import React, { createContext, useState,useEffect,useContext } from 'react';
import MenuListe from "../Menu/Menu"

// Context oluştur
const MenuContext = createContext();

// Provider komponenti
export const MenuProvider = ({ children }) => {

  
  const [menu, setMenu] = useState('Today');
  const [menuList,setMenuList]=useState(MenuListe)
  const [foundMenu,setFoundMenu]=useState()
  const [tarih,setTarih]=useState()
  
  useEffect(()=>{
    console.log("MenuContext mounth oldu")
    // const fetchMenu = async () => {
    //   try {
    //     setMenuList(MenuListe)
    //   } catch (error) {
    //     console.error('Error fetching menu:', error)
    //   }
    // }
    //fetchMenu()
    
    return ()=>{
      console.log("MenuContext unmounth oldu")
    }
    },[])
    useEffect(()=>{
      console.log("MenuContext render oldu")
      //console.log(MenuList)
    })
  return (
    <MenuContext.Provider value={{ menu, setMenu,menuList,setMenuList,foundMenu,setFoundMenu,tarih,setTarih }}>
      {children}
    </MenuContext.Provider>
  );
};

// MenuContext'i kullanmak için bir hook oluşturun
export const useMenuCntx = () => {
  return useContext(MenuContext);
};
