const Menu = [
  {
    date: '2024-12-01',
    meals: {
      breakfast: [
        { name: 'Baharatlı Elma Dilim Patates' },
        { name: 'Haşlanmış Yumurta' },
        { name: 'Çeçil/Dil/Örgü Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Bal+Tereyağ' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Mercimek Çorba / Köz Biber Çorba' },
        { name: 'Kaşarlı Köfte + Izgara Domates+Biber / Karışık Dolma+Yoğurt' },
        { name: 'Salçalı Makarna' },
        { name: 'Karışık Salata' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-02',
    meals: {
      breakfast: [
        { name: 'Peynirli Omlet' },
        { name: 'Açma/Poğaça/Simit' },
        { name: 'Labne Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Mevsim Sebzeleri Söğüş' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Ezogelin Çorba / Düğün Çorba' },
        { name: 'Çiftlik Kebabı / Mücver + Yoğurt' },
        { name: 'Bulgur Pilavı' },
        { name: 'Çikolatalı/Meyveli Yaş Pasta' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-03',
    meals: {
      breakfast: [
        { name: 'Karışık Kızartma' },
        { name: 'Haşlanmış Yumurta' },
        { name: 'Beyaz Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Reçel Çeşitleri' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Mercimek Çorba / Şehriye Çorba' },
        { name: 'Tavuk Izgara + Fırında Sebze / Nohut Tava' },
        { name: 'Köri Soslu Makarna' },
        { name: 'Ayran' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-04',
    meals: {
      breakfast: [
        { name: 'Sade Omlet' },
        { name: 'Peynirli-Patatesli Milföy Börek' },
        { name: 'Kaşar Peyniri' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Sürülebilir Çikolata' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Ezogelin Çorba / Şehriye Çorba' },
        { name: 'İzmir Köfte / Bezelye Yemeği' },
        { name: 'Şehriyeli Pirinç Pilavı' },
        { name: 'Çoban Salata' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-05',
    meals: {
      breakfast: [
        { name: 'Sosis Kokteyl(Salçalı veya Kızartma)' },
        { name: 'Haşlanmış Yumurta' },
        { name: 'Dil / Örgü Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Muz' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Mercimek Çorba / Domates Çorba' },
        { name: 'Tavuklu Çökertme Kebabı / Mevsim Türlü' },
        { name: 'Soslu Makarna' },
        { name: 'Çiğ Köfte' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-06',
    meals: {
      breakfast: [
        { name: 'Menemen' },
        { name: 'Kahvaltı Keki (Tuzlu)' },
        { name: 'Beyaz Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Helva' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Ezogelin Çorba / Kremalı Sebze Çorba' },
        { name: 'Etsiz Kuru Fasulye / Karışık Sebze Graten' },
        { name: 'Mercimekli Bulgur Pilavı' },
        { name: 'Turşu' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-07',
    meals: {
      breakfast: [
        { name: 'Patates Kızartması' },
        { name: 'Haşlanmış Yumurta' },
        { name: 'Kaşar Peyniri' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Mevsim Sebzeleri Söğüş' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Mercimek Çorba / Tarhana Çorba' },
        { name: 'Tavuklu Pirinç Pilavı / Lahana Sarma' },
        { name: 'Karışık Salata' },
        { name: 'Ayran + Şekerpare' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-08',
    meals: {
      breakfast: [
        { name: 'Kaşarlı Omlet' },
        { name: 'Dere Otlu Poğaça' },
        { name: 'Beyaz Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Tahinli Pekmez' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Ezogelin Çorba / Toyga Çorba' },
        { name: 'Rosto Köfte + Patates Püresi / Falafel Köfte+Yoğurt' },
        { name: 'Peynirli Erişte' },
        { name: 'Mandalina' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-09',
    meals: {
      breakfast: [
        { name: 'Karışık Pizza' },
        { name: 'Haşlanmış Yumurta' },
        { name: 'Krem Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Reçel Çeşitleri' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Mercimek Çorba / Romen Çorba' },
        { name: 'Şinitzel + elma dilim patates / Karnabahar Kızartması+Yoğurt' },
        { name: 'Sebzeli kuskus' },
        { name: 'Muzlu Magnolia' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-10',
    meals: {
      breakfast: [
        { name: 'Sucuklu Yumurta' },
        { name: 'Açma/Poğaça/Simit' },
        { name: 'Beyaz Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Mevsim Sebzeleri Söğüş' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Ezogelin Çorba / Düğün Çorba' },
        { name: 'Soslu ızgara köfte +domates + biber / Etsiz Nohut Yemeği' },
        { name: 'Şehriyeli Pirinç Pilavı' },
        { name: 'Yoğurt' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-11',
    meals: {
      breakfast: [
        { name: 'Peynirli Gül Böreği' },
        { name: 'Haşlanmış Yumurta' },
        { name: 'Kaşar Peyniri' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Sürülebilir Çikolata' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Mercimek Çorba / Şehriye Çorba' },
        { name: 'Tavuk Fajita / Patates Köftesi' },
        { name: 'Meyhane Pilavı' },
        { name: 'Bahçe Salata' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-12',
    meals: {
      breakfast: [
        { name: 'Patatesli Yumurta' },
        { name: 'Kakaolu Kek' },
        { name: 'Beyaz Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Mevsim Sebzeleri Söğüş' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Ezogelin Çorba / Brokoli Çorba' },
        { name: 'Et Tantuni / Taze Fasulye Yemeği' },
        { name: 'Makarna' },
        { name: 'Ayran' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-13',
    meals: {
      breakfast: [
        { name: 'Açma/Poğaça/Simit' },
        { name: 'Haşlanmış Yumurta' },
        { name: 'Örgü/Çeçil Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Tahinli Pekmez' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Mercimek Çorba / Tutmaç Çorba' },
        { name: 'Tavuk Köfte+Elma Dilim Patates / Karışık Kızartma' },
        { name: 'Pirinç Pilavı' },
        { name: 'Yoğurtlu Cevizli Kereviz Salatası' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-14',
    meals: {
      breakfast: [
        { name: 'Sade Omlet' },
        { name: 'Çikolatalı Milföy Börek' },
        { name: 'Beyaz Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Portakal' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Ezogelin Çorba / Havuç Çorba' },
        { name: 'Ekşili Köfte / Karışık Dolma+Yoğurt' },
        { name: 'Soslu Spagetti' },
        { name: 'Supangle' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-15',
    meals: {
      breakfast: [
        { name: 'Patates Kızartması' },
        { name: 'Haşlanmış Yumurta' },
        { name: 'Kaşar Peyniri' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Reçel Çeşitleri' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Mercimek Çorba / Tarhana Çorba' },
        { name: 'Etsiz Nohut Yemeği / Sütlü Patates Yemeği' },
        { name: 'Şehriyeli Bulgur Pilavı' },
        { name: 'Turşu' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-16',
    meals: {
      breakfast: [
        { name: 'Kaşarlı Omlet' },
        { name: 'Açma/Poğaça/Simit' },
        { name: 'Beyaz Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Mevsim Sebzeleri Söğüş' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Ezogelin Çorba / Kremalı Sebze Çorba' },
        { name: 'Tavuklu Sultan Kebabı / Portakallı Kereviz' },
        { name: 'Köri Soslu Makarna' },
        { name: 'Yoğurt' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-17',
    meals: {
      breakfast: [
        { name: 'Sosis Kokteyl veya Pişi' },
        { name: 'Haşlanmış Yumurta' },
        { name: 'Kaşar Peyniri' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Tahinli Pekmez' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Mercimek Çorba / Şehriye Çorba' },
        { name: 'Hünkar Beğendi / Mücver + Yoğurt' },
        { name: 'Fıstıklı Sultan Pilavı' },
        { name: 'Cevizli Baklava' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-18',
    meals: {
      breakfast: [
        { name: 'Peynirli Omlet' },
        { name: 'Açma/Poğaça/Simit' },
        { name: 'Labne Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Bal+Tereyağ' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Ezogelin Çorba / Cennet Çorba' },
        { name: 'İzmir Köfte / Karışık Sebze Graten' },
        { name: 'Makarna' },
        { name: 'Kısır' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-19',
    meals: {
      breakfast: [
        { name: 'Baharatlı Elma Dilim Patates' },
        { name: 'Haşlanmış Yumurta' },
        { name: 'Örgü/Çeçil Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Reçel Çeşitleri' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Mercimek Çorba / Tutmaç Çorba' },
        { name: 'Tavuk Tantuni / Ratatuy' },
        { name: 'Bulgur Pilavı' },
        { name: 'Ayran' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-20',
    meals: {
      breakfast: [
        { name: 'Sucuklu Yumurta' },
        { name: 'Sade Kek' },
        { name: 'Beyaz Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Mevsim Sebzeleri Söğüş' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Ezogelin Çorba / Toyga Çorba' },
        { name: 'Etsiz Kuru Fasulye / Karnabahar Kızartması+Yoğurt' },
        { name: 'Pirinç Çorba' },
        { name: 'Mozaik Pasta' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-21',
    meals: {
      breakfast: [
        { name: 'Dere Otlu Poğaça' },
        { name: 'Haşlanmış Yumurta' },
        { name: 'Kaşar Peyniri' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Sürülebilir Çikolata' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Mercimek Çorba / Köz Biber Çorba' },
        { name: 'Konak kebabı / Falafel Köfte+Yoğurt' },
        { name: 'Şehriyeli Bulgur Pilavı' },
        { name: 'Karışık Salata' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-22',
    meals: {
      breakfast: [
        { name: 'Sade Omlet' },
        { name: 'Pişi' },
        { name: 'Beyaz Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Mandalina' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Ezogelin Çorba / Mısır Çorba' },
        { name: 'Yarım ekmek balık +söğüş / Karışık Dolma+Yoğurt' },
        { name: 'Roka Salatası' },
        { name: 'Fıstıklı İrmik Helvası' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-23',
    meals: {
      breakfast: [
        { name: 'Patates Kızartması' },
        { name: 'Haşlanmış Yumurta' },
        { name: 'Kaşar Peyniri' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Mevsim Sebzeleri Söğüş' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Mercimek Çorba / Şehriyeli Tavuk Çorba' },
        { name: 'Pideli Köfte / Karışık Kızartma' },
        { name: 'Sebzeli Pirinç Pilavı' },
        { name: 'Yoğurt' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-24',
    meals: {
      breakfast: [
        { name: 'Peynirli Omlet' },
        { name: 'Açma/Poğaça/Simit' },
        { name: 'Krem Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Reçel Çeşitleri' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Ezogelin Çorba / Brokoli Çorba' },
        { name: 'Çıtır tavuk + söğüş / Lahana Sarma' },
        { name: 'Yoğurtlu Makarna' },
        { name: 'Mercimek Köftesi' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-25',
    meals: {
      breakfast: [
        { name: 'Karışık Pizza' },
        { name: 'Haşlanmış Yumurta' },
        { name: 'Beyaz Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Elma' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Mercimek Çorba / Şehriye Çorba' },
        { name: 'Etsiz Nohut Yemeği / Pırasa Yemeği' },
        { name: 'Bulgur Pilavı' },
        { name: 'Kıbrıs Tatlısı' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-26',
    meals: {
      breakfast: [
        { name: 'Sosis Kokteyl(Salçalı veya Kızartma)' },
        { name: 'Haşlanmış Yumurta' },
        { name: 'Örgü/Çeçil Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Reçel Çeşitleri' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Ezogelin Çorba / Kremalı Tavuk Çorba' },
        { name: 'Hamburger(Patates Kızartması) / Taze Fasulye Yemeği' },
        { name: 'Makarna' },
        { name: 'Ayran' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-27',
    meals: {
      breakfast: [
        { name: 'Patatesli Yumurta' },
        { name: 'Açma/Poğaça/Simit' },
        { name: 'Kaşar Peyniri' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Sürülebilir Çikolata' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Mercimek Çorba / Tarhana Çorba' },
        { name: 'Tavuk Fajita / Fellah Köfte' },
        { name: 'Nohutlu Pirinç Pilavı' },
        { name: 'Muz' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-28',
    meals: {
      breakfast: [
        { name: 'Kaşarlı Omlet' },
        { name: 'Çikolatalı Milföy Börek' },
        { name: 'Beyaz Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Mevsim Sebzeleri Söğüş' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Ezogelin Çorba / Toyga Çorba' },
        { name: 'Beyti Kebap / Barbunya Yemeği' },
        { name: 'Şehriyeli Bulgur Pilavı' },
        { name: 'Kadayıflı cevizli muhallebi' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-29',
    meals: {
      breakfast: [
        { name: 'Patates Kavurması veya Patates Salatası' },
        { name: 'Haşlanmış Yumurta' },
        { name: 'Kaşar Peyniri' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Tahinli Pekmez' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Mercimek Çorba / Domates Çorba' },
        { name: 'Beşamel Soslu tavuk / Mevsim Türlü' },
        { name: 'Spagetti' },
        { name: 'Pembe Sultan' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-30',
    meals: {
      breakfast: [
        { name: 'Sucuklu Yumurta' },
        { name: 'Açma/Poğaça/Simit' },
        { name: 'Beyaz Peynir' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Bal+Tereyağ' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Ezogelin Çorba / Erişteli Yeşil Mercimek Çorba' },
        { name: 'Çiftlik Köfte / Kuru Fasulye Yemeği' },
        { name: 'Bulgur Pilavı' },
        { name: 'Yoğurt' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  },
  {
    date: '2024-12-31',
    meals: {
      breakfast: [
        { name: 'Karışık Pizza' },
        { name: 'Haşlanmış Yumurta' },
        { name: 'Kaşar Peyniri' },
        { name: 'Siyah/Yeşil Zeytin' },
        { name: 'Mevsim Sebzeleri Söğüş' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ],
      dinner: [
        { name: 'Mercimek Çorba / Yüksük Çorba' },
        { name: 'Şinitzel + elma dilim patates / Patates Köftesi' },
        { name: 'Kestaneli iç pilavı' },
        { name: 'Browni' },
        { name: '500 ml Su' },
        { name: 'Çeyrek Ekmek' }
      ]
    }
  }
];
  export default Menu
  