import { useEffect } from "react"
import { useMenuCntx  } from '../Context/MenuContext';

const Header = () => {
    useEffect(() => {
        console.log("header componenti mounth oldu.")

        return () => {
            console.log("header componenti unmounth oldu")
        }
    })
    useEffect(() => {
        console.log("header componenti render oldu.")
    })

    const { menu,setMenu,menuList,setMenuList,foundMenu,setFoundMenu,tarih,setTarih } = useMenuCntx()

    const handleTodayClick = () => {
        console.log("bugun tıklandı")
        setMenu('Today')
    }
    const handleTomorrowClick = () => {
        console.log("yarın tıklandı")
        setMenu('Tomorrow')
    }
    const handleThisMouthClick = () => {
        console.log("bu ay tıklandı")
        setMenu('This Month')
    }

    return (
        <>
            {/* Header Section  */}
            <header className="bg-black py-2 text-yurt-white font-playfair">
                {/* Header Container  */}
                <div className="container  flex flex-col items-center justify-center md:justify-between">
                    <h2 className="text-4xl md:text-6xl mb-3 md:mb-5 border-b border-yurt-melon ">YurtYemek.com</h2>
                    {/* Buton Kutusu  */}
                    <div className="bg-yurt-melon text-black  group rounded-3xl py-2  flex items-center justify-around w-full h-20"> {/*justify between kullanılabilir */}
                        <a onClick={handleTodayClick} className="text-2xl md:text-5xl  hover:bg-emerald-300 hover:rounded-full   transition duration-500   " href="#">Bugün</a>
                        <a onClick={handleTomorrowClick} className="text-2xl md:text-5xl  hover:bg-emerald-300 hover:rounded-full   transition duration-500   " href="#">Yarın</a>
                        <a onClick={handleThisMouthClick} className="text-2xl md:text-5xl  hover:bg-emerald-300 hover:rounded-full   transition duration-500   " href="#">Bu Ay</a>
                    </div>
                </div>
                {/* Header Container  */}
                <div className="container  ">
                </div>
            </header>
        </>
    )
}
export default Header