import { MdFreeBreakfast } from "react-icons/md";
import { MdDinnerDining } from "react-icons/md";
import { useState, useContext, useEffect } from "react"
import { useMenuCntx } from '../Context/MenuContext';

const Main = () => {

    useEffect(() => {
        console.log("main componenti mounth oldu.")
        return () => {
            console.log("main unmounth oldu")
        }
    }, [])
    useEffect(() => {
        console.log("main componenti render oldu")
        if (menu === 'Today') {
            findTodayMenu();
        } else if (menu === 'Tomorrow') {
            findTomorrowMenu();
        } else {

        }
    })

    const { menu, menuList, tarih, setTarih, foundMenu, setFoundMenu } = useMenuCntx();

    const tarihAl = (gun) => {
        const today = new Date();

        if (gun === "today") {

            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Aylar 0'dan başlar, bu yüzden 1 ekliyoruz
            const day = String(today.getDate()).padStart(2, '0'); // Gün bilgisini iki haneli hale getiriyoruz

            const formattedDate = `${year}-${month}-${day}`;

            //console.log(formattedDate);
            return formattedDate
        }
        if (gun === "tomorrow") {

            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Aylar 0'dan başlar, bu yüzden 1 ekliyoruz
            const day = String(today.getDate() + 1).padStart(2, '0'); // Gün bilgisini iki haneli hale getiriyoruz

            const formattedDate = `${year}-${month}-${day}`;

            //console.log(formattedDate);
            return formattedDate
        }
    }
    const findTodayMenu = () => {
        console.log("Bugünün menüsünü bul fonksiyonu çalıştı.")
        // Bugünün tarihini alma
        console.log("tarih bulundu")
        const tarih = tarihAl("today")
        setTarih(tarih)
        //console.log('Today\'s date:', tarih); // Bugünün gününü konsolda kontrol et
        console.log("Bugunku menu bulundu")
        const foundMenuTemp = menuList.find(menu => menu.date === tarih);
        console.log('Today\'s menu:', foundMenuTemp.meals.breakfast); // Bugünkü menüyü konsolda kontrol et
        //console.log(MenuList)
        //Bugünkü menüyü state'e ayarlama
        setFoundMenu(foundMenuTemp);
        console.log('Today\'s menu:', foundMenu);

    }
    const findTomorrowMenu = () => {
        console.log("Yarının menüsünü bul fonksiyonu çalıştı.")
        // Bugünün tarihini alma
        console.log("tarih bulundu")
        const tarih = tarihAl("tomorrow")
        setTarih(tarih)
        //console.log('Today\'s date:', tarih); // Bugünün gününü konsolda kontrol et
        console.log("yarınki menu bulundu")
        const foundMenuTemp = menuList.find(menu => menu.date === tarih);
        console.log('Tomorrow\'s menu:', foundMenuTemp.meals.breakfast); // yarınki menüyü konsolda kontrol et
        //console.log(MenuList)
        //Bugünkü menüyü state'e ayarlama
        setFoundMenu(foundMenuTemp);
        console.log('Today\'s menu:', foundMenu);

    }
    const findThisMonthMenu = () => {
        console.log("bu ay  menüsünü bul fonksiyonu çalıştı.")
        // Bugünün tarihini alma
        console.log("tarih bulundu")
        const tarih = tarihAl("today")
        setTarih(tarih)
        //console.log('Today\'s date:', tarih); // Bugünün gününü konsolda kontrol et
        console.log("bu ayki menu bulundu")
        const foundMenuAll = menuList;
        console.log('bu ayki\'s menu:', foundMenuAll); // yarınki menüyü konsolda kontrol et
        //console.log(MenuList)
        //Bugünkü menüyü state'e ayarlama
        //setFoundMenu(foundMenuTemp);
        //console.log('Today\'s menu:', foundMenu);
        return foundMenuAll

    }

    const breakfastItems = foundMenu ? foundMenu.meals.breakfast : []
    const dinnerItems = foundMenu ? foundMenu.meals.dinner : []
    const allMenu = findThisMonthMenu();
    console.log(allMenu)

    return (
        <>
            {/* Main Section  */}
            <section className="bg-black ">
                {/* Menu Container  */}
                {menu === 'Today' && (
                    <div className="container flex flex-col ">
                        {/* Title  */}
                        <h2 className="text-yurt-white text-center text-3xl md:text-5xl mb-2 md:mb-4  ">{tarih}</h2>
                        {/* Grid Area  */}
                        <div className="grid grid-cols-2 gap-2 ">
                            {/* Left Breakfast  */}
                            <div className="col-span-1  bg-yurt-melon rounded-2xl ">
                                <div className=" rounded-sm">
                                    <div className="flex items-center justify-center space-x-5  bg-emerald-300 rounded-b-2xl text-center text-xl md:text-4xl  h-18 mt-0">
                                        <MdFreeBreakfast />
                                        <h3 className=" p-1">Kahvaltı</h3>
                                    </div>
                                    <ul className="text-xl md:text-3xl ml-2 mt-2 mb-2">

                                        {
                                            breakfastItems.map((items, index) => (
                                                <>
                                                    <li key={index}>{items.name}</li>
                                                </>
                                            ))
                                        }

                                    </ul>
                                </div>
                            </div>
                            {/* Right Dinner  */}
                            <div className="col-span-1  bg-yurt-melon rounded-2xl">
                                <div className=" rounded-sm">
                                    <div className="flex items-center justify-center space-x-5 bg-emerald-300 rounded-b-2xl text-center text-xl md:text-4xl h-18 mt-0">
                                        <MdDinnerDining />
                                        <h3 className="p-1">Akşam</h3>
                                    </div>
                                    <ul className="text-xl md:text-3xl ml-2 mt-2 mb-2">
                                        {
                                            dinnerItems.map((items, index) => (
                                                <>
                                                    <li key={index}>{items.name}</li>
                                                </>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>
                )}
                {menu === 'Tomorrow' && (
                    <div className="container flex flex-col ">
                        {/* Title  */}
                        <h2 className="text-yurt-white text-center text-3xl md:text-5xl mb-2 md:mb-4  ">{tarih}</h2>
                        {/* Grid Area  */}
                        <div className="grid grid-cols-2 gap-2 ">
                            {/* Left Breakfast  */}
                            <div className="col-span-1  bg-yurt-melon rounded-2xl ">
                                <div className=" rounded-sm">
                                    <div className="flex items-center justify-center space-x-5  bg-emerald-300 rounded-b-2xl text-center text-xl md:text-4xl  h-18 mt-0">
                                        <MdFreeBreakfast />
                                        <h3 className=" p-1">Kahvaltı</h3>
                                    </div>
                                    <ul className="text-xl md:text-3xl ml-2 mt-2 mb-2">

                                        {
                                            breakfastItems.map((items, index) => (
                                                <>
                                                    <li key={index}>{items.name}</li>
                                                </>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            {/* Right Dinner  */}
                            <div className="col-span-1  bg-yurt-melon rounded-2xl">
                                <div className=" rounded-sm">
                                    <div className="flex items-center justify-center space-x-5 bg-emerald-300 rounded-b-2xl text-center text-xl md:text-4xl h-18 mt-0">
                                        <MdDinnerDining />
                                        <h3 className="p-1">Akşam</h3>
                                    </div>
                                    <ul className="text-xl md:text-3xl ml-2 mt-2 mb-2">
                                        {
                                            dinnerItems.map((items, index) => (
                                                <>
                                                    <li key={index}>{items.name}</li>
                                                </>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>
                )}
                {menu === 'This Month' && (
                    <div className="container flex flex-col ">
                        {allMenu.map((menu, index) =>
                            <>
                                {/* Title  */}
                                <h2 className="text-yurt-white text-center text-3xl md:text-5xl mb-2 md:mb-4  " >{menu.date}</h2>
                                {/* Grid Area  */}
                                <div className="grid grid-cols-2 gap-2 ">
                                    {/* Left Breakfast  */}

                                    <div className="col-span-1  bg-yurt-melon rounded-2xl ">
                                        <div className=" rounded-sm">
                                            <div className="flex items-center justify-center space-x-5  bg-emerald-300 rounded-b-2xl text-center text-xl md:text-4xl  h-18 mt-0">
                                                <MdFreeBreakfast />
                                                <h3 className=" p-1">Kahvaltı</h3>
                                            </div>
                                            <ul className="text-xl md:text-3xl ml-2 mt-2 mb-2">
                                                {menu.meals["breakfast"].map((menu, menuIndex) =>
                                                    <li>{menu.name}</li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                    {/* Right Dinner  */}
                                    <div className="col-span-1  bg-yurt-melon rounded-2xl">
                                        <div className=" rounded-sm">
                                            <div className="flex items-center justify-center space-x-5 bg-emerald-300 rounded-b-2xl text-center text-xl md:text-4xl h-18 mt-0">
                                                <MdDinnerDining />
                                                <h3 className="p-1">Akşam</h3>
                                            </div>
                                            <ul className="text-xl md:text-3xl ml-2 mt-2 mb-2">
                                                {menu.meals["dinner"].map((menu, menuIndex) =>
                                                    <li>{menu.name}</li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </section >
        </>
    )
}
export default Main