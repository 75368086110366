import Header from "./components/Header/Header"
import Main from "./components/Main/Main"
import { useEffect } from "react"
import { MenuProvider } from './components/Context/MenuContext';

function App() {
  useEffect(() => {
    console.log("app  componenti mounth oldu")

    const fetchMenu = async () => {
      try {
        //setMenu(Menu)
      } catch (error) {
        console.error('Error fetching menu:', error)
      }
    }
    //fetchMenu()


    return () => {
      console.log("app componenti unmouth oldu.")
    }
  }, [])
  useEffect(() => {
    console.log("app componenti render oldu")
  })
  return (
    <>
      <MenuProvider>
        <Header />
        <Main/>
      </MenuProvider>
    </>
  );
}
export default App;